
import {defineComponent} from "vue";
import Alert from "@/components/General/Alert.vue";
import {FlightOrderStatus} from "@/models/flight_order_status";
import orderDetailsController from "@/composables/profile_flight_details";
import {AvioneMessage} from "@/constants/error_messages";
import axios from "axios";
import {LocalDb} from "@/repository/localdb";
import moment from "moment";

export default defineComponent({
  name: "FlightOrderDetailHeader",
  components: {
    Alert,
  },
  data() {
    return {
      tokenData: LocalDb.getToken(),
      voidConfirm: false,
      disableDownloadBtn: false,
      paidStatus: FlightOrderStatus.PAID,
      bookedStatus: FlightOrderStatus.BOOKED,
      voidStatus: FlightOrderStatus.VIOD,
      baseUrl: "https://restapi.avione.uz/api/Flight/",
      downloadBtnText: this.$t(AvioneMessage.DOWNLOAD_TICKET_TEXT),
      orderEndTimeSeconds: 0,
    };
  },
  setup() {
    const {state, onVoidOrder} = orderDetailsController();
    return {...state, onVoidOrder};
  },
  mounted() {
    let expireDateString = this.order.timeTillTicket.orderAvioneExpireDateLocal;
    let expireDate = Date.parse(expireDateString);
    this.orderEndTimeSeconds = Math.floor((expireDate - new Date().getTime()) / 1000);
    if (this.orderEndTimeSeconds > 0) {
      this.onDecreaseOrderEndTime();
    } else {
      this.orderEndTimeSeconds = 0;
    }
  },
  updated() {
    this.voidConfirm = false;
  },
  methods: {
    getFormattedEndTime: function () {
      return moment
          .utc(
              moment.duration(this.orderEndTimeSeconds, "seconds").asMilliseconds()
          )
          .format("mm:ss");
    },
    onDecreaseOrderEndTime: function () {
      setInterval(() => {
        this.orderEndTimeSeconds--;
      }, 1000);
    },
    onVoidClickEvent: function () {
      if (this.order.status == this.bookedStatus) {
        this.alertShow = true;
        this.alertMessage = this.$t(AvioneMessage.PAYMENT_BOOKING_VOID_MESSAGE);
      }
    },
    onAlertCloseClickEventHandler: function () {
      this.alertShow = false;
      this.alertMessage = "";
    },
    onAlertOkClickEventHandler: function () {
      this.alertShow = false;
      this.alertMessage = "";
      this.voidConfirm = true;
      this.onVoidOrder(this);
    },
    async downloadItem(orderId: number) {
      this.disableDownloadBtn = true;
      this.downloadBtnText = this.$t(AvioneMessage.PLEASE_WAIT);
      const response = await axios.get(this.baseUrl + orderId, {
        responseType: "blob",
        headers: {Authorization: "bearer " + this.tokenData?.token},
      });
      const blob = new Blob([response.data], {type: "application/pdf"});
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "ticket_" + orderId + ".pdf";
      link.click();
      URL.revokeObjectURL(link.href);
      this.downloadBtnText = this.$t(AvioneMessage.DOWNLOAD_TICKET_TEXT);
    },
    // getFormattedEndTime: function(order: any) {},
  },
});
